
mat-toolbar {
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.5);

  &.topbar {
    padding: 0 0 0 15px;
    display: flex;
  }

  .navbar-header {
    width: 180px;
    text-align: center;

    .navbar-brand {
      display: flex;
      line-height: 18px;
      align-items: center;

      b {
        margin-right: 5px;
      }
    }
  }

  .profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
}

/*******************
 Topbar
*******************/
.topbar {
  position: relative;
  z-index: 2;

  &-divider {
    width: 1px;
    height: 42px;
    background: #070808;
    margin: 0 20px;
  }

  &-exit{
    width: 48px;
    height: 65px;
    border: none;
    border-radius: 0 !important;
    background: $primary-color;
    color: white;
    margin-left: 20px !important;
  }

  &-options {
    flex-grow: 1;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

.example-sidenav-container {
  flex: 1;
}

.example-sidenav-container {
  flex: 1 0 auto;
}
