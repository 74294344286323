@use '@angular/material' as mat;

/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables

//PALETTE
$mat-primary: (
  50:  #e8eaf4,
  100: #c4cbe5,
  200: #9daad3,
  300: #7789c1,
  400: #596fb4,
  500: #3a56a8,
  600: #334e9e,
  700: #2a4492,
  800: #233a86,
  900: #162a70,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);
$mat-secondary: (
  50: #e4f4ff,
  100: #bce2ff,
  200: #92d1ff,
  300: #64beff,
  400: #3eb0ff,
  500: #12a2ff,
  600: #1093f6,
  700: #0152B2,
  800: #076fd1,
  900: #076fd1,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

$body-font: Roboto, sans-serif;
$heading-font: Roboto, sans-serif;

$primary-color: mat.get-color-from-palette($mat-primary, 900);
$secondary-color: mat.get-color-from-palette($mat-secondary, 700);

$body-color: #ffffff;
$heading-color: #515353;
$body-text-color: #757575;
$theme-color: #1e88e5;

/*bootstrap Color*/
$danger: #FF2E2E;
$success: #46A34A;
$warning: #ffb22b;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #C8C8C8;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;

/*Normal Color*/
$white: #ffffff;
$purple: #7460ee;
$theme-color-alt: #26c6da;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$radius: 4px;


@mixin xs() {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin gt-sm() {
  @media (min-width: 960px) {
    @content;
  }
}

@mixin lt-sm() {
  @media (max-width: 960px) {
    @content;
  }
}

@mixin gt-md() {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin lt-md() {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin gt-lg() {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin lt-lg() {
  @media (max-width: 1920px) {
    @content;
  }
}

@mixin xl() {
  @media (min-width: 1920px) and (max-width: 4999.99px) {
    @content;
  }
}

@mixin lt-xl() {
  @media (max-width: 1919.99px) {
    @content;
  }
}

@mixin lg() {
  @media (min-width: 1280px) and (max-width: 1919.99px) {
    @content;
  }
}
