.mat-card {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
  margin: 0 15px 30px;
  padding: 0px !important;
  border-radius: $radius;

  .mat-card-content {
    font-size: 1rem;
    line-height: 23px;
    padding: 24px;
  }
  .mat-card-image {
    width: calc(100% + 0px);
    margin: 0 0px 0px 0px;
  }
  .mat-card-header {
    padding: 12px;
    border-radius: $radius $radius 0 0;
    .mat-card-title {
      margin-bottom: 2px;
      font-size: 20px;
    }
    .mat-card-subtitle,
    .mat-card-subtitle:not(:first-child) {
      margin-bottom: 0px;
      margin-top: 0px;
      font-size: 14px;
    }
    &.text-white {
      .mat-card-title {
        color: $white;
      }
      .mat-card-subtitle {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  .mat-card-title {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .mat-card-subtitle {
    margin-bottom: 20px;
  }
  .mat-card-actions {
    margin: 0px !important;
  }
}

// forms
.mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.25);
}
.mat-form-field {
  flex-grow: 1;
}
