/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

/*******************
Main sidebar
******************/

/*******************
use profile section
******************/

.user-profile {
  position: relative;
  background-size: cover;
  display: none;
  @include xs() {
    display: block;
  }

  .profile-img {
    width: 50px;
    margin-left: 30px;
    padding: 31px 0;
    border-radius: 100%;

    &::before {
      -webkit-animation: 2.5s blow 0s linear infinite;
      animation: 2.5s blow 0s linear infinite;
      position: absolute;
      content: "";
      width: 50px;
      height: 50px;
      top: 31px;
      margin: 0 auto;
      border-radius: 50%;
      z-index: 0;
    }

    @-webkit-keyframes blow {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }
    @keyframes blow {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }

    img {
      width: 100%;
      border-radius: 100%;
    }
  }

  .profile-text {
    padding: 5px 0px;
    position: relative;
    cursor: pointer;
    white-space: nowrap;

    > a {
      color: $white !important;
      width: 100%;
      padding: 6px 30px;
      background: rgba(0, 0, 0, 0.5);
      display: block;
      white-space: nowrap;

      &:after {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
  }
}


.mat-expansion-panel {
  box-shadow: none !important;
  background-color: transparent;

  &-header {
    padding: 0 !important;
  }

  &-body {
    padding: 0 !important;
  }
}
