// global

* {
  outline: none;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  font-family: $body-font;
  margin: 0;
  overflow-x: hidden;
  color: $body-text-color;
  font-weight: 300;
  background: $inverse;

  mat-sidenav-content.page-wrapper {
    transition: margin 0.3s ease;
  }
}

html {
  position: relative;
  min-height: 100%;
  background: $white;
}

strong {
  font-weight: 700;
}

.main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mat-drawer-container {
  background: $body-color;
}

.is-cursor{
  cursor: pointer;
}

a:hover,
a:focus,
a {
  text-decoration: none;
}

a.link {
  color: $heading-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $theme-color;
  }
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

hr {
  border: 0;
  height: 1px;
  background: $border;
}

html body .mdi:before,
html body .mdi-set {
  line-height: initial;
}

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: $danger;
  background-color: $light-danger;
  border-radius: 0.25rem;
}

.box {
  border-radius: $radius;
  padding: 10px;
}

.list-inline {
  margin-left: 0px;
  padding: 0px;

  li {
    display: inline-block;
    list-style: none;
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.button-row button {
  margin-bottom: 5px;
  margin-right: 5px;
}

html body {
  .position-relative {
    position: relative;
  }

  // list
  .clear {
    clear: both;
  }

  ol li {
    margin: 5px 0;
  }

  // display
  .dl {
    display: inline-block;
  }

  .db {
    display: block;
  }

  //   opacity
  .op-5 {
    opacity: 0.5;
  }

  .op-3 {
    opacity: 0.3;
  }

  .rounded {
    border-radius: $radius;
  }

  //   flex
  .ml-auto {
    margin-left: auto;
  }

  .d-flex {
    display: flex;
  }

  .no-shrink {
    flex-shrink: 0;
  }

  .oh {
    overflow: hidden;
  }

  .hide {
    display: none;
  }

  .img-circle {
    border-radius: 100%;
  }

  .radius {
    border-radius: $radius;
  }

  .no-shadow {
    box-shadow: none;
  }

  //   align vertical
  .vt {
    vertical-align: top;
  }

  .vm {
    vertical-align: middle;
  }

  .vb {
    vertical-align: bottom;
  }

  //   horizontal align
  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .pb-0 {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }


  .text-left {
    text-align: left;
  }

  //   border
  .b-0 {
    border: none;
  }

  .b-r {
    border-right: 1px solid $border;
  }

  .b-l {
    border-left: 1px solid $border;
  }

  .b-b {
    border-bottom: 1px solid $border;
  }

  .b-t {
    border-top: 1px solid $border;
  }

  .b-all {
    border: 1px solid $border;
  }
}

.mat-card {
  margin: 0 0 30px 0 !important;
}

.divider-box {
  display: block;
  margin: 20px 0;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

html .page-wrapper {
  min-height: calc(100vh - 64px);

  .page-content {
    margin: 0 auto;
    padding: 15px 15px;
    width: 97%;
  }
}

.w-100 {
  width: 100%;
}

.page {

  &-wrapper{
    &-gradient{
      background: rgb(255,255,255);
      background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 49%, rgba(200,200,200,1) 100%);
      background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 49%, rgba(200,200,200,1) 100%);
      background: linear-gradient(180deg, rgba(255,255,255,1) 49%, rgba(200,200,200,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#c8c8c8",GradientType=1);
    }
    &-gray{
      background-color: #C8C8C8;
    }
    &-blue{
      background-color: $primary-color;

      .panel-header-inside-icon,
      .panel-header-inside-title,
      .panel-header-options-close{
        color: $white;
      }

      .list-button-icon{
        color: $secondary-color;
      }
    }
  }

  &-image-bg{
    background: url('/assets/images/bg_page.jpg') no-repeat top center;
    background-size: cover;
  }

  &-header {
    width: 100%;
    border-radius: 29.5px;
    background: #f4f4f4;
    padding: 8px 13px;
  }

  &-avatar {
    width: 47px;
    min-width: 47px;
    height: 47px;
    border-radius: 50%;
    background: #0152b2;

    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  &-table {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 26px;
    border-radius: 12px;
    background: #f4f4f4;

    td.mat-cell {
      border: none;
    }

    .mat-sort-header-content{
      width: 100%;
    }

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;

      tbody {
        padding-top: 20px;
      }

      th {
        font-weight: 500;
        font-size: 12px;
        text-align: left;
        color: #515353;
        border-bottom: 1px solid #000000;
        padding: 0 21px;
        line-height: 32px;
      }

      tr:nth-child(even) {
        td:last-child {
          border-radius: 0 16px 16px 0;
        }

        td:first-child {
          border-radius: 16px 0 0 16px;
        }

        td {
          background-color: #ffffff;
        }
      }

      td {
        font-size: 12px;
        text-align: left;
        color: #515353;
        padding: 0 21px;
        line-height: 32px;
      }
    }
  }
}

.mat-caption {
  color: #9b9b9b;
}

.mat-button, .mat-raised-button, .mat-flat-button, .mat-stroked-button {
  line-height: 37px !important;
  border-radius: 18.5px !important;
}

.mat-stroked-button {
  line-height: 32px !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  line-height: 17px;
  padding: 0 0.75em 0 2.25em !important
}

.mat-form-field-outline-start {
  border-radius: 28px 0 0 28px !important;
  min-width: 28px !important;
}

.mat-form-field-outline-end {
  border-radius: 0 28px 28px 0 !important;
}

.mat-form-field-label {
  color: #004AA6;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: #474949;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #004AA6 !important;

  label {
    color: #004AA6 !important;
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  border-width: 2px !important;
}

.mat-dialog-content {
  @include gt-sm() {
    padding: 0 51px 24px !important;
  }
}

.btn-lg {
  min-width: 156px !important;
  line-height: 56px !important;
  border-radius: 40px !important;
  font-size: 16px;
}

.btn-default{
  border: none;
  color: $white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  padding: 15px 20px;
  border-radius: 25px;
  background-color: $primary-color;
}
.btn-secondary{
  background-color: $secondary-color;
}
.btn-danger{
  background-color: $danger;
}

.mat-dialog-container {
  border-radius: 16px !important;
}


.mat-title {
  color: #474949;
}

.mat-stroked-button {
  border-width: 2px !important;
}

.btnOutline {
  color: #474949;
  border: 2px solid #474949;
}

.backIcon {
  background-color: #0152B2;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  align-items: flex-start;
}


.material-icons.color_white {
  color: #FFFFFF;
}

.btn-float{
  display: flex;
  justify-content: end;
  /*right: 30px;
  bottom: 50px;
  z-index: 10;
  position: fixed;*/

  &-icon{
    font-size: 35px;
    width: 35px !important;
    height: 35px !important;
    color: $primary-color;
  }
}

.modal-title {
  margin-bottom: 20px;
  background-color: #f4f4f4;
  border-radius: 40px;
  padding: 0 10px 0 28px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    margin: 18px 0;
  }
}

.tableBtn {
  display: flex;
  justify-content: end;
}

.content{
  width: 90%;
  max-width: 780px;
  margin: 0 auto;
  display: block;

  &-small{
    max-width: 550px;
  }
}

.mat-bottom-sheet-container{
  padding-top: 20px !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.panel{

  &-search{
    width: 100%;

    &-box{
      width: 100%;
      margin: 25px 0;

      .mat-form-field{
        width: 100%;
      }
    }
    &-result{}
    &-table{
      font-size: 12px;

      tr{

        td{
          padding: 8px 5px;
          color: $body-text-color;
        }

        &:nth-child(even){
          td{
            background-color: #f5f5f5;
          }
        }
        &.line{
          &-header{
            td{
              color: #000000;
              font-size: 18px;
              font-weight: 400;
              padding-bottom: 5px;
            }
            &-code{
              font-size: 14px;
            }
            &-label{
              font-weight: 400;
            }
          }
          &-border{
            td{
              border-bottom: 1px solid $heading-color;

              &.no-border{
                border: none;
              }
            }
          }
          &-white{
            td{
              background-color: $white;
            }
          }
        }
      }
      &.no-striped{
        td{
          padding: 8px 5px;
          background-color: $white !important;
        }
      }
    }
  }

  &-header{
    display: flex;
    margin: 60px 0;
    justify-content: space-between;

    &-col{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    &-title{
      margin: 0;
      font-size: 45px;
      color: $primary-color;
      text-transform: uppercase;
    }
    &-description{
      font-size: 16px;
    }
    &-date{
      font-size: 22px;
      font-weight: 400;
      color: $primary-color;
    }

    &-options{
      display: flex;
      margin: 0 0 15px 0;
      align-items: center;
      justify-content: end;

      &-close{
        font-size: 40px;
        color: $heading-color;
        width: 40px !important;
        height: 40px !important;
      }

      &-item {
        padding: 15px;
        display: flex;
        color: $white;
        margin: 0 50px 0 0;
        border-radius: 12px;
        flex-direction: column;
        background-color: $primary-color;
      }
      &-icon{
        font-size: 15px;
        color: $white;
      }
      &-label{
        color: $white;
        font-size: 14px;
        padding: 0px 0 0px;
      }
    }

    &-inside{
      display: flex;
      margin: 40px 0 40px;
      justify-content: space-between;

      &-icon{
        font-size: 40px;
        width: 40px !important;
        height: 40px !important;
        color: $secondary-color;
      }

      &-title{
        flex-grow: 1;
        font-size: 42px;
        margin: 0 0 0 40px;
        color: $primary-color;
      }


    }

  }

  &-sheet{
    &-header{
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-icon{
        font-size: 24px;
        width: 24px !important;
        height: 24px !important;
        color: $secondary-color;
      }
      &-title{
        flex-grow: 1;
        font-size: 28px;
        margin: 0 0 0 30px;
        color: $primary-color;
      }
      &-close{
        font-size: 35px;
        width: 35px !important;
        height: 35px !important;
        color: $heading-color;
      }
    }
  }

}

.list-button{
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &-item{
    width: 100%;
    padding: 30px;
    margin-bottom: 15px;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    background-color: $white;

    &.disabled{
      opacity: 0.2;
      cursor: default;
    }

    &.half{
      padding: 50px 30px 70px;
      width: 47%;
    }

    &-row{
      display: flex;
      justify-content: space-between;
    }

    &-col{
      display: flex;
      flex-direction: column;
    }
    &-value{
      color: #D80101;
      font-size: 45px;
      font-weight: bold;
    }
  }
  &-icon{
    color: $secondary-color;
  }
  &-label{
    font-size: 28px;
    padding: 20px 0 5px;
    color: $primary-color;
  }
  &-legend{
    font-size: 12px;
    color: $body-text-color;
  }
}

.table-field{
  width: 72px;
  border: none;
  font-size: 15px;
  text-align: center;
}

.resume-detail{
  margin: 30px 0;

  &-label{
    font-size: 14px;
    margin: 0 0 5px 0;
    color: $primary-color;
  }
  &-item{
    display: flex;
    justify-content: space-between;

    &-title{
      font-size: 17px;
      margin: 0 0 10px 0;
      color: #000000;
    }
    &-icon{
      color: $primary-color;
    }
  }

  &-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-item{
      flex-grow: 1;
      padding: 5px 5px;
      border-right: 1px solid $primary-color;

      &:last-child{
        border: none;
      }

      strong{
        color: $primary-color;
      }
    }
  }
}

.line-table-highlight{
  font-size: 16px;
  color: $body-text-color;

  strong{
    color: $primary-color;
  }
}

.detail{
  &-header{
    display: flex;
    align-items: center;
    margin: 0 0 -1px 0;
    padding: 40px 20px 50px;
    background-color: $muted;
    justify-content: space-between;

    &-blue{
      background-color: $primary-color;

      .panel-header-options-close,
      .detail-header-icon,
      .detail-header-info-title,
      .detail-header-info-description {
        color: $white;
      }

    }

    &-icon{
      font-size: 30px;
      width: 30px !important;
      height: 30px !important;
      color: $heading-color;
    }
    &-info{
      flex-grow: 1;
      display: flex;
      padding: 0 30px 0 0;
      // flex-direction: column;
      justify-content: space-between;

      &-col{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }

      &-title{
        font-size: 28px;
        margin: 0 0 0 0;
        color: $primary-color;
      }
      &-description{
        font-size: 16px;
        margin: 0 0 0 0;
        color: $secondary-color;
      }
    }
  }
  &-body{
    width: 100%;
    float: left;
    min-height: 78vh;
    background-color: $white;

    &-dark{
      background-color: $muted;
    }
    &-blue{
      padding-top: 20px;
      background-color: $primary-color;

      .btn-default{
        color: $primary-color;
        background-color: $white;
      }
    }

    &-top{
      color: $white;
      display: flex;
      flex-wrap: wrap;
      padding: 15px 20px;
      align-items: center;
      margin: -10px 0 0 0;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      justify-content: space-between;
      background-color: $secondary-color;

      &.align-items-start{
        align-items: start !important;
      }

      &-title{
        font-size: 28px;
      }
      &-description{
        font-size: 22px;
      }

      &-col{
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        &-title{
          font-size: 16px;
          font-weight: 400;
          margin: 0 0 15px 0;
        }

        &-list{
          display: flex;
          flex-wrap: wrap;

          &-item{
            display: flex;
            margin: 0 10px 0 0;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            &.active{
              .detail-body-top-col-list-thumb{
                border: 2px solid $white;
              }
            }
          }
          &-thumb{
            width: 50px;
            height: 50px;
            border-radius: 6px;
            border: 2px solid $primary-color;

            &.no-border{
              border: none;
            }
          }
          &-label{
            margin: 5px 0;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }

      &-btn{
        color: $white;
        padding: 15px;
        font-size: 16px;
        font-weight: 400;
        margin: 0 10px 0 0;
        border-radius: 6px;
        border: 1px solid $white;
        background-color: transparent;

        &.active{
          color: $primary-color;
          background-color: $white;
        }
      }
    }
  }
}

.line-header{

  &:hover{
    .table-option-more{
      opacity: 0;
      visibility: hidden;
      transform: translate(50px, 0);
    }
    .table-option-items{
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }
}

.table-option{
  display: flex;
  position: relative;
  align-items: center;


  &-more{
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  }

  &-items{
    right: 0;
    top: -17px;
    opacity: 0;
    display: flex;
    position: absolute;
    visibility: hidden;
    justify-content: start;
    transform: translate(180px, 0);
    transition: transform 0.3s ease;
  }

  &-btn{
    border: none;
    color: $white;
    padding: 15px 20px;

    &-icon{
      color: $white;
      font-size: 24px;
      width: 24px !important;
      height: 24px !important;
    }

  }
}

.modal{
  &-box{
    &-title{
      font-size: 45px;
      line-height: 50px;
      color: $primary-color;
    }
    &-description{
      font-size: 16px;
      margin: 0 0 50px 0;
      color: $body-text-color;
    }
  }
}

.card{
  padding: 20px;
  margin: 0 0 40px 0;
  border-radius: 12px;
  background-color: $white;

  &-header{
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-icon{
      color: $primary-color;
    }
    &-title{
      flex-grow: 1;
      font-size: 28px;
      margin: 0 0 0 20px;
      color: $primary-color;
    }

    &-options{
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-dark{
        display: flex;
        min-height: 39px;
        align-items: center;
        padding: 0px 10px 0px 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: $primary-color;

        &-icon{
          color: $white;
        }
      }
      &-description{
        flex-grow: 1;
        display: flex;
        font-size: 14px;
        min-height: 39px;
        font-weight: 400;
        align-items: center;
        padding: 0 15px 0 10px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: $white;
      }
    }
  }
  &-body{}

  &-custom{
    padding: 0 0 20px;

    .card-header{
      padding: 20px 20px 130px 20px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      background-color: $secondary-color;

      &-icon{
        color: $white;
      }
      &-title{
        color: $white;
      }
    }
  }

  &-middle{
    margin: -90px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &-col{
      display: flex;
      width: 45%;
      text-align: center;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    &-divider{
      margin: 50px 0 0 0;
      color: $secondary-color;
    }

    &-title{
      color: $white;
      font-size: 22px;
      margin: 0 0 20px 0;
    }
    &-thumb{
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 12px;
      border: 4px solid $white;
    }
  }
}

.panel-resume{

}

.table-default{
  width: 100%;

  th{
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    padding: 20px 15px;
    color: $primary-color;
    border-bottom: 1px solid $body-text-color;
  }

  tr{
    &:nth-child(odd){
      td{
        background-color: #f5f5f5;
      }
    }
  }

  td{
    padding: 15px;
    font-size: 14px;
    text-align: left;
  }
}
