/* You can add global styles to this file, and also import other style files */
@import 'front/variable';
@import 'front/theme';

@import 'front/mixins/spacing';
@import 'front/mixins/color';
@import 'front/mixins/font';
@import 'front/mixins/display';
@import 'front/mixins/borders';

@import 'front/component/global';
@import 'front/component/heading';

@import 'front/component/header';
@import 'front/component/sidebar';
@import 'front/component/spinner';
@import 'front/component/card';
@import 'front/component/dashboard';

@import 'front/responsive';


/* MODAL */
.cdk-overlay-backdrop {
  &.cdk-overlay-backdrop-showing {
    background: rgba(0, 0, 0, .32) !important;
    opacity: 1 !important;
  }
}

.border-none{
  border: 0 !important;
}
.background-none{
  background-color: transparent !important;
}


.mat-drawer-inner-container {
  flex-direction: column;
  display: flex !important;
  justify-content: space-between;
}

.sidebar {
  mat-tree{
    padding-left: 0!important;
  }
  &-footer {
    &-icon {
      color: white;
      transition: transform 0.3s ease;

      svg {
        fill: white;
      }
    }

    &-container {
      width: 100%;
      display: flex;
      overflow: hidden;
      text-align: right;
      position: relative;
      align-items: center;
      justify-content: end;
      padding: 10px 15px 0 0;
      background-color: $secondary-color;
    }
  }
}

.title {
  font-weight: bold;
  color: #474949;
}

.tooltip {
  width: 86px;
  border-radius: 6px;
  padding: 8px;

  &_porcent {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #162a70;

  }

  &_date {
    font-size: 9px;
    line-height: 16px;
    text-align: center;
    color: #474949;
  }
}

.text-center{
  text-align: center!important;
}

.text-end{
  text-align: end!important;
}

.mouse-click{
  cursor: pointer;
}

.table-similarity {
  &-custom{
    tbody{
      border-bottom: 5px solid #ccc;
    }
  }
  &-text-vertical{
    font-size: 10px;
    writing-mode: vertical-lr;
  }
}

.mat-bottom-sheet-container-medium{
  @media screen and (min-width: 980px) {
    min-width: 660px !important;
  }
}

.mat-bottom-sheet-container{
  min-height: 70vh;
}
