.d-flex {
  display: flex;
}

.d-none{
  display: none;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: start;
}

.justify-content-center{
  justify-content: center;
}
