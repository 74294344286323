.rounded-left {
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
}

.rounded-right {
  border-top-right-radius: 27px;
  border-bottom-right-radius: 27px;
}

.border-0 {
  border-width: 0 !important;
}

.border-right {
  border-right: 1px solid #707070;
}

.border-left {
  border-left: 1px solid #707070;
}
